import * as React from "react";

const SVGPolygon = (props) => (
	<svg
		width={98}
		height={87}
		viewBox="0 0 98 87"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M68.5278 -1.92979e-07C72.1004 -6.50241e-07 75.4017 1.90599 77.188 5L96.4145 38.3013C98.2008 41.3953 98.2008 45.2073 96.4145 48.3013L77.188 81.6025C75.4017 84.6966 72.1004 86.6025 68.5278 86.6025L30.0748 86.6025C26.5021 86.6025 23.2008 84.6965 21.4145 81.6025L2.18802 48.3013C0.401693 45.2073 0.401693 41.3953 2.18802 38.3013L21.4145 5C23.2008 1.90599 26.5021 -6.72753e-07 30.0748 -3.43462e-07L68.5278 -1.92979e-07Z"
			fill="#FCD786"
		/>
	</svg>
);

export default SVGPolygon;
