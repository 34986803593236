import * as React from "react";

const SVGDottedPattern = (props) => (
	<svg
		width={140}
		height={325}
		viewBox="0 0 140 325"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<circle cx={7.93103} cy={174.931} r={7.93103} fill="#fbba00" />
		<circle cx={7.93103} cy={259.759} r={7.93103} fill="#fbba00" />
		<circle cx={82.4137} cy={259.759} r={7.93103} fill="#fbba00" />
		<circle cx={82.4137} cy={174.931} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={174.931} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={259.759} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={259.759} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={174.931} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={174.931} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={259.759} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={259.759} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={174.931} r={7.93103} fill="#fbba00" />
		<circle cx={57.5863} cy={174.931} r={7.93103} fill="#fbba00" />
		<circle cx={57.5863} cy={259.759} r={7.93103} fill="#fbba00" />
		<circle cx={132.069} cy={259.759} r={7.93103} fill="#fbba00" />
		<circle cx={132.069} cy={174.931} r={7.93103} fill="#fbba00" />
		<circle cx={7.93103} cy={203.207} r={7.93103} fill="#fbba00" />
		<circle cx={7.93103} cy={288.035} r={7.93103} fill="#fbba00" />
		<circle cx={82.4137} cy={288.035} r={7.93103} fill="#fbba00" />
		<circle cx={82.4137} cy={203.207} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={203.207} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={288.035} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={288.035} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={203.207} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={203.207} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={288.035} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={288.035} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={203.207} r={7.93103} fill="#fbba00" />
		<circle cx={57.5863} cy={203.207} r={7.93103} fill="#fbba00" />
		<circle cx={57.5863} cy={288.035} r={7.93103} fill="#fbba00" />
		<circle cx={132.069} cy={288.035} r={7.93103} fill="#fbba00" />
		<circle cx={132.069} cy={203.207} r={7.93103} fill="#fbba00" />
		<circle cx={7.93103} cy={231.483} r={7.93103} fill="#fbba00" />
		<circle cx={7.93103} cy={316.31} r={7.93103} fill="#fbba00" />
		<circle cx={82.4137} cy={316.31} r={7.93103} fill="#fbba00" />
		<circle cx={82.4137} cy={231.483} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={231.483} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={316.31} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={316.31} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={231.483} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={231.483} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={316.31} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={316.31} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={231.483} r={7.93103} fill="#fbba00" />
		<circle cx={57.5863} cy={231.483} r={7.93103} fill="#fbba00" />
		<circle cx={57.5863} cy={316.31} r={7.93103} fill="#fbba00" />
		<circle cx={132.069} cy={316.31} r={7.93103} fill="#fbba00" />
		<circle cx={132.069} cy={231.483} r={7.93103} fill="#fbba00" />
		<circle cx={7.93103} cy={7.93103} r={7.93103} fill="#fbba00" />
		<circle cx={7.93103} cy={92.7587} r={7.93103} fill="#fbba00" />
		<circle cx={82.4137} cy={92.7587} r={7.93103} fill="#fbba00" />
		<circle cx={82.4137} cy={7.93116} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={7.93116} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={92.7587} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={92.7587} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={7.93116} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={7.93116} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={92.7587} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={92.7587} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={7.93116} r={7.93103} fill="#fbba00" />
		<circle cx={57.5863} cy={7.93116} r={7.93103} fill="#fbba00" />
		<circle cx={57.5863} cy={92.7587} r={7.93103} fill="#fbba00" />
		<circle cx={132.069} cy={92.7587} r={7.93103} fill="#fbba00" />
		<circle cx={132.069} cy={7.93116} r={7.93103} fill="#fbba00" />
		<circle cx={7.93103} cy={36.2069} r={7.93103} fill="#fbba00" />
		<circle cx={7.93103} cy={121.035} r={7.93103} fill="#fbba00" />
		<circle cx={82.4137} cy={121.035} r={7.93103} fill="#fbba00" />
		<circle cx={82.4137} cy={36.2069} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={36.2069} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={121.035} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={121.035} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={36.2069} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={36.2069} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={121.035} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={121.035} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={36.2069} r={7.93103} fill="#fbba00" />
		<circle cx={57.5863} cy={36.2069} r={7.93103} fill="#fbba00" />
		<circle cx={57.5863} cy={121.035} r={7.93103} fill="#fbba00" />
		<circle cx={132.069} cy={121.035} r={7.93103} fill="#fbba00" />
		<circle cx={132.069} cy={36.2069} r={7.93103} fill="#fbba00" />
		<circle cx={7.93103} cy={64.4828} r={7.93103} fill="#fbba00" />
		<circle cx={7.93103} cy={149.31} r={7.93103} fill="#fbba00" />
		<circle cx={82.4137} cy={149.31} r={7.93103} fill="#fbba00" />
		<circle cx={82.4137} cy={64.4828} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={64.4828} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={149.31} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={149.31} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={64.4828} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={64.4828} r={7.93103} fill="#fbba00" />
		<circle cx={32.7587} cy={149.31} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={149.31} r={7.93103} fill="#fbba00" />
		<circle cx={107.241} cy={64.4828} r={7.93103} fill="#fbba00" />
		<circle cx={57.5863} cy={64.4828} r={7.93103} fill="#fbba00" />
		<circle cx={57.5863} cy={149.31} r={7.93103} fill="#fbba00" />
		<circle cx={132.069} cy={149.31} r={7.93103} fill="#fbba00" />
		<circle cx={132.069} cy={64.4828} r={7.93103} fill="#fbba00" />
	</svg>
);

export default SVGDottedPattern;
