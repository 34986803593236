import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Link from "./link";
import { GatsbyImage } from "gatsby-plugin-image";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

const BlogCard = ({ title, image, uri, imageAlt, date }) => {
	return (
		<Col className="pb-5 " xs={12}>
			<Link className="text-decoration-none" to={`/blog/${uri}`}>
				<div
					style={{ boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}
					className="bg-primary"
				>
					<Row className="align-items-center   text-center">
						<Col className="" md={4} lg={3} xl={2}>
							<GatsbyImage
								className="w-100 blog-image "
								alt={imageAlt}
								image={image}
							></GatsbyImage>
						</Col>
						<Col className="p-4" md={8} lg={9} xl={10}>
							<h2 className="pb-lg-4 pb-2 text-decoration-none text-black">
								{title}
							</h2>
							<p className="text-decoration-none text-black">{date}</p>
						</Col>
					</Row>
				</div>
			</Link>
		</Col>
	);
};

export default BlogCard;
