import React from "react";
import BlogCard from "../../components/blogCard";
import Layout from "../../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import SVGDottedPattern from "../../components/SVG/dotted-pattern";
import SVGHRBlogBgPoly from "../../components/SVG/hr-blog-bg-poly";
import SVGPolygon from "../../components/SVG/polygon";

const BlogPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: wpMediaItem(title: { eq: "Blog-Hero-Image" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				blogPosts: allWpPost(
					filter: { title: { ne: "Website Images" } }
					sort: { fields: dateGmt, order: DESC }
				) {
					nodes {
						id
						title
						slug
						excerpt
						blogFields {
							date
							featureImage {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											quality: 100
											placeholder: BLURRED
											layout: CONSTRAINED
										)
									}
								}
							}
						}
					}
				}
			}
		`
	);
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `https://hivehrsolutions.co.uk`,
					id: `https://hivehrsolutions.co.uk`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Blog",
				item: {
					url: `https://hivehrsolutions.co.uk/blog`,
					id: `https://hivehrsolutions.co.uk/blog`,
				},
			},
		],
	};

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg.localFile.childImageSharp.gatsbyImageData;

	if (typeof window !== "undefined") {
		return (
			<Layout>
				<Helmet>
					{" "}
					<script type="application/ld+json">
						{JSON.stringify(breadcrumb)}
					</script>
				</Helmet>
				<GatsbySeo
					title="Blog | Hive HR Solutions"
					description="Blog page / placeholder"
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}/blog`,
						title: "Blog | Hive HR Solutions",
						description: "Blog page / placeholder",
						images: [
							{
								url: `${data.heroImg?.localFile.publicURL}`,
								width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
								height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
								alt: `${data.heroImg?.altText}`,
							},
						],
					}}
				/>
				<section className="position-relative">
					<SVGHRBlogBgPoly
						style={{ zIndex: -2 }}
						className="position-absolute end-0 bottom--40 d-none d-lg-block "
					/>
					<Container>
						<Row>
							<Col className="py-lg-7 pt-5 pb-6">
								<div className="position-relative d-inline-block">
									<h1 className="pt-1 mb-3">Hive HR Solutions Blog</h1>
									<SVGPolygon
										className="position-absolute d-none d-xl-block"
										style={{ zIndex: -1, right: "-1.3rem", top: "-1.5rem" }}
									/>
								</div>

								<hr style={{ width: "45%" }} className="hr-primary mb-4 " />
								<GatsbyImage
									className="d-lg-none"
									alt={data.heroImg.altText}
									image={heroImg}
								/>
								<div
									className="position-relative d-lg-block d-none  "
									style={{ height: "28rem", width: "50rem" }}
								>
									<GatsbyImage
										className="position-absolute"
										style={{ height: "28rem", width: "50rem" }}
										alt={data.heroImg.altText}
										image={heroImg}
									/>{" "}
									<SVGDottedPattern
										style={{ right: "-3rem", bottom: "-3.2rem", zIndex: "-2" }}
										className="position-absolute  d-none d-lg-block "
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="pb-lg-10 pb-5">
					<Container>
						<Row>
							<Col className="pb-4" xs={12}>
								<h2 className="">Blog articles</h2>
								<hr className="hr-primary w-40" />
							</Col>
						</Row>
						<Row className="justify-content-center">
							<Col>
								{data.blogPosts.nodes.map((post) => (
									<BlogCard
										key={post.id}
										title={post.title}
										uri={post.slug}
										date={post.blogFields.date}
										image={
											post.blogFields.featureImage?.localFile?.childImageSharp
												?.gatsbyImageData
										}
										imageAlt={post.blogFields.featuredImage?.altText}
									></BlogCard>
								))}
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		);
	}
};

export default BlogPage;
