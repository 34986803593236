import * as React from "react";

const SVGHRBlogBgPoly = (props) => (
	<svg
		width={366}
		height={886}
		viewBox="0 0 366 886"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M550.896 219.807C554.468 219.807 557.77 221.713 559.556 224.807L702.941 473.157C704.728 476.251 704.728 480.063 702.941 483.157L559.556 731.508C557.77 734.602 554.468 736.508 550.896 736.508L264.125 736.508C260.552 736.508 257.251 734.602 255.465 731.508L112.079 483.157C110.293 480.063 110.293 476.251 112.079 473.157L255.465 224.807C257.251 221.713 260.552 219.807 264.125 219.807L550.896 219.807Z"
			fill="#EEEEEE"
			fillOpacity={0.3}
		/>
		<path
			d="M559.827 149.159C563.4 149.159 566.701 151.065 568.488 154.159L711.873 402.51C713.659 405.604 713.659 409.416 711.873 412.51L568.488 660.861C566.701 663.955 563.4 665.861 559.827 665.861L273.056 665.861C269.484 665.861 266.183 663.955 264.396 660.861L121.011 412.51C119.224 409.416 119.224 405.604 121.011 402.51L264.396 154.159C266.183 151.065 269.484 149.159 273.056 149.159L559.827 149.159Z"
			fill="#EEEEEE"
			fillOpacity={0.3}
		/>
	</svg>
);

export default SVGHRBlogBgPoly;
